import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/FcyOGwoWFxo">
    <SEO title="It's Getting Worse - The Week That Changed the World" />
  </Layout>
)

export default SermonPost
